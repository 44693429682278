
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormCollectionSelect from '@/components/common/form/FormCollectionSelect.vue';
import FormDate from '@/components/common/form/FormDate.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import PitchMoment from '@/models/PitchMoment';
import {Templates} from '@/models/Template';
import {Tooltip} from 'bootstrap';
import isBefore from 'date-fns/isBefore';
import userTeams from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnResource,
        FormDate,
        FormCollectionSelect,
        FormGroup,
    },
    props: {
        editPitchMoment: {
            type: Object as PropType<PitchMoment>,
            default: () => new PitchMoment(),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'saved-pitch-moment',
    ],
    setup(props) {
        const attributes = props.editPitchMoment.id
            ? props.editPitchMoment.attributes
            : {
                teamId: userTeams.teamProgram.value.id,
            };

        return {
            pitchMoment: ref<PitchMoment>(new PitchMoment(attributes)),
            templates: ref(new Templates([], {
                filters: {
                    scripting: true,
                },
            })),
            pitchNameInput: ref<typeof FormGroup>(),
            tooltip: ref<Tooltip>(),
        };
    },
    computed: {
        validDates(): boolean {
            /*
             * If the deadline and presentation date are set, return whether the
             * deadline date is before the presentation date, otherwise return
             * true.
             */
            return this.pitchMoment.deadlineAt && this.pitchMoment.presentationAt
                ? isBefore(
                    new Date(this.pitchMoment.deadlineAt),
                    new Date(this.pitchMoment.presentationAt),
                )
                : true;
        },
    },
    watch: {
        showTooltip(show: boolean) {
            if (show) {
                this.createTooltip();
            } else if (this.tooltip) {
                this.tooltip.dispose();
            }
        },
        'pitchMoment.name': function handler() {
            this.toggleTooltip();
        },
    },
    methods: {
        createTooltip() {
            if (!this.pitchNameInput) {
                return;
            }

            this.tooltip = new Tooltip(this.pitchNameInput.$el.querySelector('#name'), {
                customClass: 'tooltip-pulsate-left',
                offset: [0, 200 - this.pitchNameInput.$el.offsetWidth],
                placement: 'right',
                title: this.$t('pitchMoments.form.writePitchMoment'),
                trigger: 'manual',
            });

            this.toggleTooltip();
        },
        toggleTooltip() {
            if (!this.tooltip) {
                return;
            }

            if (this.pitchMoment.name === '') {
                this.tooltip.show();
            } else {
                this.tooltip.hide();
            }
        },
        async savePitchMoment() {
            if (this.readonly) {
                return;
            }

            try {
                await this.pitchMoment.save();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                if (this.tooltip) {
                    this.tooltip.update();
                }

                return;
            }

            this.$emit('saved-pitch-moment', this.pitchMoment);
        },
    },
});
