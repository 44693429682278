
import Datepicker from 'vue3-date-time-picker';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import {defineComponent} from 'vue';

/**
 * Shows a date picker component.
 * Makes use of the vue3-date-time-picker package to handle all logic.
 */
export default defineComponent({
    components: {
        Datepicker,
    },
    props: {
        maxDate: {
            type: [String, Date],
            default: () => (addYears(new Date(), 5)),
        },
        minDate: {
            type: [String, Date],
            default: () => (addYears(new Date(), -5)),
        },
        minDateInclusive: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inputClass(): string {
            const classes = ['form-control'];

            if (this.readonly) {
                classes.push('form-control-plain');
            }

            return classes.join(' ');
        },
        minimalDate(): Date {
            const date = new Date(this.minDate);

            return this.minDateInclusive
                ? date
                : addDays(date, 1);
        },
    },
});
