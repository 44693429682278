import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormCollectionSelect = _resolveComponent("FormCollectionSelect")!
  const _component_FormDate = _resolveComponent("FormDate")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.savePitchMoment && _ctx.savePitchMoment(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('pitchMoments.form.programDetails')), 1),
        _createVNode(_component_FormGroup, {
          ref: "pitchNameInput",
          modelValue: _ctx.pitchMoment.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pitchMoment.name) = $event)),
          class: "w-75 mt-6",
          "error-key": "name",
          errors: _ctx.pitchMoment.errors,
          label: _ctx.$t('pitchMoments.form.name'),
          name: "name",
          placeholder: _ctx.$t('pitchMoments.form.name'),
          readonly: _ctx.readonly
        }, null, 8, ["modelValue", "errors", "label", "placeholder", "readonly"]),
        _createVNode(_component_FormCollectionSelect, {
          modelValue: _ctx.pitchMoment.template,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pitchMoment.template) = $event)),
          class: "w-75 mt-4",
          collection: _ctx.templates,
          "error-key": "templateId",
          errors: _ctx.pitchMoment.errors,
          "key-prop": "id",
          label: _ctx.$t('pitchMoments.form.typeOfPitch'),
          "label-prop": "name",
          placeholder: _ctx.$t('pitchMoments.form.typeOfPitch'),
          readonly: _ctx.readonly
        }, null, 8, ["modelValue", "collection", "errors", "label", "placeholder", "readonly"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('pitchMoments.form.deadlines')), 1),
        _createVNode(_component_FormGroup, {
          class: "w-75 mt-6",
          "error-key": "deadlineAt",
          errors: _ctx.pitchMoment.errors,
          "help-text": !_ctx.validDates && !_ctx.readonly ? _ctx.$t('pitchMoments.form.deadlineBeforePresentation') : '',
          label: _ctx.$t('pitchMoments.form.deadlineDate')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormDate, {
              modelValue: _ctx.pitchMoment.deadlineAt,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pitchMoment.deadlineAt) = $event)),
              placeholder: _ctx.$t('pitchMoments.form.deadlineDate'),
              readonly: _ctx.readonly
            }, null, 8, ["modelValue", "placeholder", "readonly"])
          ]),
          _: 1
        }, 8, ["errors", "help-text", "label"]),
        _createVNode(_component_FormGroup, {
          class: "w-75 mt-4",
          "error-key": "presentationAt",
          errors: _ctx.pitchMoment.errors,
          label: _ctx.$t('pitchMoments.form.presentationDate')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormDate, {
              modelValue: _ctx.pitchMoment.presentationAt,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pitchMoment.presentationAt) = $event)),
              "min-date": _ctx.pitchMoment.deadlineAt,
              placeholder: _ctx.$t('pitchMoments.form.presentationDate'),
              readonly: _ctx.readonly
            }, null, 8, ["modelValue", "min-date", "placeholder", "readonly"])
          ]),
          _: 1
        }, 8, ["errors", "label"])
      ])
    ]),
    (!_ctx.readonly)
      ? _renderSlot(_ctx.$slots, "save-button", {
          key: 0,
          disabled: !_ctx.validDates,
          resource: _ctx.pitchMoment
        }, () => [
          _createVNode(_component_BtnResource, {
            class: "mt-12",
            disabled: !_ctx.validDates,
            resource: _ctx.pitchMoment,
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`pitchMoments.actions.${_ctx.editPitchMoment.id ? 'updateDetails' : 'addPitchMoment'}`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "resource"])
        ])
      : _createCommentVNode("", true)
  ], 32))
}