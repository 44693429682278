import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    "auto-apply": "",
    clearable: false,
    "enable-time-picker": false,
    format: "dd/MM/yyyy",
    "hide-input-icon": _ctx.readonly,
    "input-class-name": _ctx.inputClass,
    "max-date": _ctx.maxDate,
    "min-date": _ctx.minimalDate,
    "month-name-format": "long",
    readonly: _ctx.readonly,
    "text-input": "",
    transitions: false
  }, null, 8, ["hide-input-icon", "input-class-name", "max-date", "min-date", "readonly"]))
}